/**
 * 
 * @param {RequestInfo} url 
 * @param {?RequestInit} options 
 * @returns {Promise<any>}
 */
export async function api(url, options = {}) {
    if ('body' in options) {
        options.body = JSON.stringify(options.body);
    }
    const r = await fetch(`${process.env.VUE_APP_API}${url}`, {
        headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
        },
        credentials: 'include',
        ...options,
    });
    if (!r.ok) {
        const { error, details } = await r.json();
        if (error) {
            throw new Error(error);
        }
        if (details) {
            throw new Error(details);
        }
        throw new Error('Непредвиденная ошибка');
    }
    return r.json();
}

export default {
    install(Vue) {
        Vue.prototype.$api = api;
    }
};