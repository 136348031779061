<template>
    <v-card :loading="loading" :disabled="loading">
        <v-card-title>Примеры</v-card-title>
        <v-btn
            @click="loadSearchData"
        >
            Загрузить в поисковик
        </v-btn>
        <v-card-text>
            <v-alert type="success">Просто пример</v-alert>
            <v-alert type="error">Страницу удалить потом, она не нужна</v-alert>
            <v-switch v-model="status" readonly />
            <v-btn @click="showError" color="error">Показать ошибку</v-btn>
        </v-card-text>
    </v-card>
</template> 

<script>
import { MeiliSearch } from 'meilisearch';

export default {
    name: "Test",
    data: () => ({
        status: false,
        loading: false,
        searchApi: process.env.MEILISEARCH_URL
    }),
    async mounted() {
        this.loading = true;
        try {
            const { status } = await this.$api("/admin/test");
            this.status = status;
        } catch (e) {
            this.$error(e.message);
        }
        this.loading = false;
    },
    methods: {
        showError () {
            this.$error(String(Date.now()));
        },
        loadSearchData () {
            this.loadDocuments();
            this.loadNews();
            this.loadActivity();
            this.loadObjects();
            this.loadPages();
        },
        async loadDocuments () {
            const on = 100000;
            const pg = 1;
            try {
                const { list } = await this.$api(`/admin/documents?${new URLSearchParams({
                    pg,
                    on,
                })}`);
                let data = [];
                list.forEach((singleNew, index) => {
                    const item = {
                        id: list[index].id,
                        name: list[index].name,
                    };
                    data.push(item);
                });
                const client = new MeiliSearch({ host: this.searchApi });
                client.index('oopn_documents').addDocuments(data);
            } catch (e) {
                this.$error(e.message);
            }
        },
        async loadNews () {
            const on = 100000;
            const pg = 1;
            try {
                const { list } = await this.$api(`/admin/news?${new URLSearchParams({
                    pg,
                    on,
                })}`);
                let data = [];
                list.forEach((singleNew, index) => {
                    const item = {
                        id: list[index].id,
                        name: list[index].name,
                    };
                    data.push(item);
                });
                const client = new MeiliSearch({ host: this.searchApi });
                client.index('oopn_news').addDocuments(data);
            } catch (e) {
                this.$error(e.message);
            }
        },
        async loadActivity () {
            const on = 100000;
            const pg = 1;
            try {
                const { list } = await this.$api(`/admin/activities?${new URLSearchParams({
                    pg,
                    on,
                })}`);
                let data = [];
                list.forEach((singleNew, index) => {
                    const item = {
                        id: list[index].id,
                        name: list[index].name,
                    };
                    data.push(item);
                });
                const client = new MeiliSearch({ host: this.searchApi });
                client.index('oopn_activity').addDocuments(data);
            } catch (e) {
                this.$error(e.message);
            }
        },
        async loadObjects () {
            const on = 100000;
            const pg = 1;
            try {
                const { list } = await this.$api(`/admin/objects?${new URLSearchParams({
                    pg,
                    on,
                })}`);
                let data = [];
                list.forEach((singleNew, index) => {
                    const item = {
                        id: list[index].id,
                        name: list[index].name,
                    };
                    data.push(item);
                });
                const client = new MeiliSearch({ host: this.searchApi });
                client.index('oopn_objects').addDocuments(data);
            } catch (e) {
                this.$error(e.message);
            }
        },
        async loadPages () {
            const on = 100000;
            const pg = 1;
            try {
                const { list } = await this.$api(`/admin/pages?${new URLSearchParams({
                    pg,
                    on,
                })}`);
                let data = [];
                list.forEach((singleNew, index) => {
                    const item = {
                        id: list[index].id,
                        name: list[index].name,
                    };
                    data.push(item);
                });
                const client = new MeiliSearch({ host: this.searchApi });
                client.index('oopn_pages').addDocuments(data);
            } catch (e) {
                this.$error(e.message);
            }
        },
    },
};
</script>