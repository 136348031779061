<template>
    <v-app>
        <template v-if="!isLogin">
            <v-overlay :value="loading" z-index="1000">
                <v-progress-circular indeterminate size="64" />
            </v-overlay>
            <v-navigation-drawer
                app
                :temporary="!isHome"
                :permanent="isHome"
                v-model="drawer"
            >
                <v-list>
                    <v-list-item>
                        <v-list-item-title>Панель управления</v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-divider />
                <v-list>
                    <v-list-item
                        :to="{ name: 'News' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-newspaper-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Новости</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'Events' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-newspaper-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>События</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'Tags' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-tag-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Категории новостей</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'Pages' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-newspaper-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Страницы</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'MainMenu' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-menu-open</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Главное меню</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'MenuTypes' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-tag-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Типы пунктов меню</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'Objects' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-atlassian</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Объекты</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'ObjectTypes' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-tag-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Типы объектов</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'ObjectCategories' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-tag-multiple</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Категории объектов</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'Regions' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-web</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Районы</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'Documents' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-file-document-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Документы</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'DocumentCategories' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-file-document-multiple-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Группы документов</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'Activities' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-motion-play</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Деятельность</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'Resources' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-link</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Ресурсы</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'VideoLinks' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-video</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Ссылки на видео</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        :to="{ name: 'ContactRequest' }"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-table</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Обратная связь</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item :to="{ name: 'Test' }">
                        <v-list-item-icon>
                            <v-icon>mdi-newspaper-variant</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Прочее</v-list-item-title>
                    </v-list-item> -->
                </v-list>
                <v-divider />
                <v-list>
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Выход</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <v-app-bar app>
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    v-if="!isHome"
                />
                <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
                <div class="admin-title-project">
                    Государственная инспекция по охране объектов культурного наследия Республики Хакасия
                </div>
            </v-app-bar>
        </template>

        <v-main>
            <v-container :fill-height="isLogin" fluid>
                <router-view />
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        drawer: false,
        loading: false,
    }),

    computed: {
        isLogin() {
            return this.$route.name == "Login";
        },
        isHome() {
            return this.$route.name == "Home";
        },
    },
    methods: {
        async logout() {
            this.loading = true;
            await this.$api("/logout");
            this.$router.push({ name: "Login" });
            this.drawer = false;
            this.loading = false;
        },
    },
};
</script>

<style scoped>
    .admin-title-project {
        color: #bbb;
        position: absolute;
        right: 15px;
        font-size: 12px;
    }
</style>
