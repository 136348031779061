import store from '@/store';
import { api } from '@p/api';
/**
 * @type {import("vue-router").NavigationGuard}
 */
const guard = async (to, from, next) => {
    const { status } = await api("/test");
    if (!status) {
        store.commit('auth/logined', false);
        if (to.name != 'Login') {
            next({ name: 'Login' });
            return;
        }
    } else {
        store.commit('auth/logined', true);
    }
    next();
};
export default guard;
