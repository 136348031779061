<template>
    <v-form
        @submit.prevent="send"
        ref="form"
        :disabled="loading"
        class="mx-auto"
    >
        <v-card width="400" :loading="loading" :disabled="loading">
            <v-card-title>Вход</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Логин"
                            :rules="req"
                            v-model="username"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Пароль"
                            :rules="req"
                            v-model="password"
                            type="password"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn type="submit" text color="primary">Продолжить</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template> 

<script>
export default {
    name: "Login",
    data: () => ({
        username: "",
        password: "",
        loading: false,
    }),
    mounted() {
        if (this.$store.state.auth.logined) {
            this.$router.push({ name: "Home" });
        }
    },
    methods: {
        async send() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.loading = true;
            try {
                await this.$api("/login", {
                    method: "POST",
                    body: {
                        username: this.username,
                        password: this.password,
                    },
                });
                this.$router.push({ name: "Home" });
            } catch (e) {
                this.$error(e.message);
            }
            this.loading = false;
        },
    },
    computed: {
        req: () => [(v) => !!v || "Заполните поле"],
    },
};
</script>
