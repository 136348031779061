import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@v/Home.vue';
import Login from '@v/Login.vue';
import Test from '@v/Test.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Домашняя страница',
        },
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'Вход',
        },
        component: Login,
    },
    {
        path: '/test',
        name: 'Test',
        meta: {
            title: 'Test',
        },
        component: Test,
    },
    {
        path: '/events',
        name: 'Events',
        meta: {
            title: 'Мероприятия',
        },
        component: () => import('@v/Events'),
    },
    {
        path: '/news',
        name: 'News',
        meta: {
            title: 'Новости',
        },
        component: () => import('@v/News'),
    },
    {
        path: '/tags',
        name: 'Tags',
        meta: {
            title: 'Категории новостей',
        },
        component: () => import('@v/Tags'),
    },
    {
        path: '/pages',
        name: 'Pages',
        meta: {
            title: 'Страницы',
        },
        component: () => import('@v/Pages'),
    },
    {
        path: '/mainmenu',
        name: 'MainMenu',
        meta: {
            title: 'Главное меню',
        },
        component: () => import('@v/MainMenu'),
    },
    {
        path: '/menutypes',
        name: 'MenuTypes',
        meta: {
            title: 'Типы пунктов меню',
        },
        component: () => import('@v/MenuTypes'),
    },
    {
        path: '/objects',
        name: 'Objects',
        meta: {
            title: 'Объекты',
        },
        component: () => import('@v/Objects'),
    },
    {
        path: '/object_types',
        name: 'ObjectTypes',
        meta: {
            title: 'Типы объектов',
        },
        component: () => import('@v/ObjectTypes'),
    },
    {
        path: '/object_categories',
        name: 'ObjectCategories',
        meta: {
            title: 'Категории объектов',
        },
        component: () => import('@v/ObjectCategories'),
    },
    {
        path: '/regions',
        name: 'Regions',
        meta: {
            title: 'Районы',
        },
        component: () => import('@v/Regions'),
    },
    {
        path: '/documents',
        name: 'Documents',
        meta: {
            title: 'Документы',
        },
        component: () => import('@v/Documents'),
    },
    {
        path: '/document_categories',
        name: 'DocumentCategories',
        meta: {
            title: 'Группы документов',
        },
        component: () => import('@v/DocumentCategories'),
    },
    {
        path: '/activities',
        name: 'Activities',
        meta: {
            title: 'Деятельность',
        },
        component: () => import('@v/Activities'),
    },
    {
        path: '/resources',
        name: 'Resources',
        meta: {
            title: 'Ресурсы',
        },
        component: () => import('@v/Resources'),
    },
    {
        path: '/video-links',
        name: 'VideoLinks',
        meta: {
            title: 'Ссылки на видео',
        },
        component: () => import('@v/VideoLinks'),
    },
    {
        path: '/contact-request',
        name: 'ContactRequest',
        meta: {
            title: 'Обратная связь',
        },
        component: () => import('@v/ContactRequest'),
        props: (req) => ({
            pg: Number(req.query.pg ?? 1),
        }),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

import seo from './seo';
router.beforeEach(seo);

import auth from './auth';
router.beforeEach(auth);

export default router;
